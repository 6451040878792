import useState from 'rc-util/lib/hooks/useState';
import * as React from 'react';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
function isThenable(thing) {
    return !!(thing && !!thing.then);
}
const ActionButton = props => {
    const clickedRef = React.useRef(false);
    const ref = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const { close } = props;
    const onInternalClose = (...args) => {
        close === null || close === void 0 ? void 0 : close(...args);
    };
    React.useEffect(() => {
        let timeoutId = null;
        if (props.autoFocus) {
            timeoutId = setTimeout(() => {
                var _a;
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
            });
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);
    const handlePromiseOnOk = (returnValueOfOnOk) => {
        if (!isThenable(returnValueOfOnOk)) {
            return;
        }
        setLoading(true);
        returnValueOfOnOk.then((...args) => {
            setLoading(false, true);
            onInternalClose(...args);
            clickedRef.current = false;
        }, (e) => {
            // Emit error when catch promise reject
            // eslint-disable-next-line no-console
            console.error(e);
            // See: https://github.com/ant-design/ant-design/issues/6183
            setLoading(false, true);
            clickedRef.current = false;
        });
    };
    const onClick = (e) => {
        const { actionFn } = props;
        if (clickedRef.current) {
            return;
        }
        clickedRef.current = true;
        if (!actionFn) {
            onInternalClose();
            return;
        }
        let returnValueOfOnOk;
        if (props.emitEvent) {
            returnValueOfOnOk = actionFn(e);
            if (props.quitOnNullishReturnValue && !isThenable(returnValueOfOnOk)) {
                clickedRef.current = false;
                onInternalClose(e);
                return;
            }
        }
        else if (actionFn.length) {
            returnValueOfOnOk = actionFn(close);
            // https://github.com/ant-design/ant-design/issues/23358
            clickedRef.current = false;
        }
        else {
            returnValueOfOnOk = actionFn();
            if (!returnValueOfOnOk) {
                onInternalClose();
                return;
            }
        }
        handlePromiseOnOk(returnValueOfOnOk);
    };
    const { type, children, prefixCls, buttonProps } = props;
    return (React.createElement(Button, Object.assign({}, convertLegacyProps(type), { onClick: onClick, loading: loading, prefixCls: prefixCls }, buttonProps, { ref: ref }), children));
};
export default ActionButton;
